
import { BrowserRouter as Routers, Routes, Route, useLocation, Navigate } from "react-router-dom";
import Login from './components/Login/Login';
import Select from "./components/Select/Select";

import Today from "./components/Today/Today";
import Performance from "./components/Performance/Performance";
import BotActivity from "./components/BotActivity/BotActivity";

import MyAccount from "./components/MyAccount/MyAccount";
import Customer from "./components/Customer/Customer";
import { Provider } from "react-redux";
import LoadingData from "./components/LoadingData/LoadingData";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Redux/store";
import Website from "./components/Website/Website";
import Help from "./components/Help/Help";

import Baseline from "./components/AFP/Baseline/Baseline";
import Current from "./components/AFP/Current/Current";
import Generic from "./components/AFP/Generic";
import TopBar from "./SharedComponents/TopBar/TopBar";
import SideBar from "./SharedComponents/SideBar/SideBar";
import Dynamiclinks from "./components/AFP/DynamicLinks";
import Affiliates from "./components/Affiliates/Affiliates";
import BotTypesDescription from "components/BotTypesDescription/BotTypesDescription";
import "./App.scss";
import React, { useTransition } from "react";
import { useSelector } from "react-redux";
import api from "veracityapi";
import MessageBar from "SharedComponents/MessageBar/MessageBar";

const App = () => {
	const testData = useSelector(state => state?.detectModes);
	let addFraudAllow = (testData?.data?.afp_status || "off") !== "off";
	let addWebThreatAllow = (testData?.data?.wtp_status || "off") !== "off";
	const [isPending, startTransition] = useTransition();
	const isAdmin = localStorage.getItem("admin") === "true";

	return (
		<>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<div className="app">
						<Routers>
							<SideBar />
							<div style={{ width: '100%' }}>
								<TopBar />
								{/* <MessageBar/> */}
								<main className="main">
								<MessageBar/>
									<Routes>									
										<Route path="/" element={<Login />} />
										{/* {!api.isUserLoggedIn() && <Route path="*" element={<Navigate to="/" />} />} */}
										<Route path="/select" element={<Select />} />
										<Route path="/loading" element={<LoadingData />} />

										{(addWebThreatAllow || isAdmin) &&
											<Route path="/wtp">
												<Route index element={<Today />} />
												<Route path="today" element={<Today />} />
												<Route path="performance" element={<Performance />} />
												<Route path="bot-activity" element={<BotActivity />} />
											</Route>
										}

										<Route path="/manage">
											<Route path="user" element={<MyAccount />} />
											<Route path="company" element={<Customer />} />
											<Route path='website' element={<Website />} />
										</Route>

										<Route path="/help">
											<Route index element={<Help />} />
											<Route path="dynamic-links" element={<Dynamiclinks />} />
											<Route path="bot-types" element={<BotTypesDescription />} />
										</Route>

										{(addFraudAllow || isAdmin) &&
											<Route path="/afp">
												<Route path="comparison" element={<Generic title={'Comparision'} />} />
												<Route path="report" element={<Generic title={'Report'} />} />
												{isAdmin &&
													<Route path="baseline" element={<Baseline />}>
														<Route index element={<Baseline />} />
														<Route path="campaign/:adType/:campaignId" element={<Baseline />} />
														<Route path="link/:adType/:linkId" element={<Baseline />} />
													</Route>
												}
												<Route path="current" >
													<Route index element={<Current />} />
													<Route path="campaign/:adType/:campaignId" element={<Current />} />
													<Route path="link/:adType/:linkId" element={<Current />} />
												</Route>
											</Route>
										}

										<Route path="affiliates" element={<Affiliates />} />

										{/* Redirect old routes */}
										<Route path="today" element={<Navigate to="/wtp/today" />} />
										<Route path="performance" element={<Navigate to="/wtp/performance" />} />
										<Route path="bot_activity" element={<Navigate to="/wtp/bot-activity" />} />

										{/* Catch all redirect to default page */}
										{addWebThreatAllow && <Route path="*" element={<Navigate to="/wtp/today" />} />}
										{(!addWebThreatAllow && addFraudAllow) && <Route path="*" element={<Navigate to="/afp/current" />} />}
										{(!addWebThreatAllow && !addFraudAllow) && <Route path="*" element={<Navigate to="/select" />} />}

									</Routes>
								</main>

							</div>
						</Routers>
					</div>
				</PersistGate>
			</Provider>
		</>
	);
};
export default App;
