import React from "react";
import Loading from "../../SharedComponents/LoadingScreen/Loading";
import LoginFoot from "../../SharedComponents/LoginProcess/LoginFoot/LoginFoot";
import { Navigate } from "react-router-dom";
import { SelectHead } from "../../SharedComponents/LoginProcess/LoginHead/LoginHead";
import { useDispatch, useSelector } from "react-redux";
import { dashboardSelectedGraphs } from "../../Redux/actions";
import { loadDataForAllTabs } from "../../Redux/actions";
import EvaluationPanelController from "../Performance/PerformancePanels/EvaluationPanel/EvaluationPanelController";
import FinishedSessionsPanelController from "../BotActivity/BotActivityPanels/FinishedSessionsPanel/FinishedSessionsPanelController";
import GeographicalPanelController from "../BotActivity/BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyPanelController";
import BlockedBotsCo2Controller from "../BotActivity/BotActivityPanels/BlockedBotsCO2/BlockedBotsCo2Controller";
import BlockedSessionsGeographyPanelController from "../BotActivity/BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyPanelController";
import CurrentActivityPanelController from "../Today/TodayPanels/CurrentActivityPanel/CurrentActivityPanelController";
import TodayFinishedSessionsPanelController from "../Today/TodayPanels/TodayFinishedSessionsPanel/TodayFinishedSessionsPanelController";
import AffiliatesController from "../Affiliates/AffiliatesController";
import ManageCustomerData from "../../SharedComponents/ManageCustomerData/ManageCustomersData";
import HumanActivityPanel from "../../components/Performance/PerformancePanels/HumanActivityPanel/HumanActivityPanelController";
import BotDetailsData from "../Today/TodayPanels/BotDetails/BotDetailsData";
import BotTypeDescription from "../BotTypesDescription/BotTypeDescription";
import BotRiskController from "components/Today/TodayPanels/BotRisk/TodayBotRiskController";
import BotRiskControllerforBotActivity from "components/BotActivity/BotActivityPanels/BotRiskForBotActivity/BotRiskForBotActivityContoller";
import api from "../../veracityapi";
import { Adnetworks } from "components/AFP/Constants";
import bApi from "../../API/BeaconAPI";
import { useLocation } from "react-router-dom";
import "moment-timezone";
import AppMaintenance from "../Login/AppMaintenance/AppMaintenance";

const LoadingData = () => {
	if (!api.isUserLoggedIn()) {
		api.logout("Fatal Error")
	};
	const dispatch = useDispatch();
	const location = useLocation();
	const status = localStorage.getItem("version");
	const search = location.search.slice(1);
	const checkoutId = search.split("&")[0];
	localStorage.setItem('CheckoutID', checkoutId.split("=")[1]);
	const [reduxComplete, setReduxComplete] = React.useState(false);
	const websiteGUID = localStorage.getItem("selected_site");
	const detectModes = { ...useSelector(state => state?.detectModes?.data) };
	const [addWebThreatAllow, setAddWebThreatAllow] = React.useState(null);
	const isAdmin = localStorage.getItem("admin") === "true";

	React.useEffect(() => {
		setAddWebThreatAllow(detectModes.wtp_status !== "off");
	}, [])

	const importArr = [];

	const dataCalls = [
		FinishedSessionsPanelController({
			timeRange: 'Prev 7 Days',
		}, dispatch),
		GeographicalPanelController({
			timeRange: 'Prev 7 Days',
		}, dispatch),
		EvaluationPanelController({
			timeRange: 'Prev 7 Days',
		}, dispatch),
		BlockedSessionsGeographyPanelController({
			timeRange: 'Prev 7 Days',
		}, dispatch),
		BlockedBotsCo2Controller({
			timeRange: 'Prev 7 Days'
		}, dispatch),
		AffiliatesController({
			timeRange: 'Prev 7 Days'
		}, dispatch),
		BotRiskControllerforBotActivity({
			timeRange: 'Prev 7 Days'
		}, dispatch),
		HumanActivityPanel({
			timeRange: "Prev 7 Days"
		}, dispatch),
		BotTypeDescription({}, dispatch),
		ManageCustomerData({}, dispatch),
	];
	const getAFPWebsiteTokens = () => {

		bApi.setCurrencySymbol();

	}
	const getAFPWebsiteId = () => {
		return new Promise((resolve, reject) => {
			api.GET(`website/${websiteGUID}/afpWebsite`)
				.then(res => {
					localStorage.setItem("bcn-website-id", res?.item?.['afp_website_id']);
					resolve(true);
				}).catch(e => {
					reject(true);
					console.log("Get AFP website error: ", e);
				})
		});
	};

	const getDataForTodayPage = async () => {
		Promise.all(
			[
				BotDetailsData({ timeRange: 'Today' }, dispatch, 1),
				CurrentActivityPanelController({}, dispatch),
				TodayFinishedSessionsPanelController({}, dispatch),
				BotRiskController({ timeRange: 'Prev 7 Days' }, dispatch, 1),
			]
		)
			.then(() => {
				setReduxComplete(true)
			}).catch(e => console.log("Error While Loading Today data: ", e))
	}

	const getDataForPages = async () => {
		dispatch(loadDataForAllTabs(true))
		dispatch(dashboardSelectedGraphs(importArr));
		Promise.all(dataCalls).then(() => {
			dispatch(loadDataForAllTabs(false))
		}).catch(e => console.log("Error While Loading All pages data: ", e));
	};

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "#662D91"
	});

	const getAllAdPlatforms = () => {
		bApi.getAdplatforms().then(ap => {
			let platformList = [];
			ap.map(i => {
				platformList.push(Adnetworks.filter(j => j.name === i[0])[0]);
			});
			dispatch({ type: "UPDATE_AVAILABLE_AD_NETWORKS", payload: platformList });
			//select first available network as default;
			dispatch({ type: "UPDATE_CURRENT_ADNETWORK", payload: [platformList?.[0]?.id ?? 1] });

		}).catch(e => {

		})
	};


	React.useEffect(() => {
		if (detectModes.wtp_status !== "off" || isAdmin) getDataForPages();
		if (detectModes.wtp_status !== "off" || isAdmin) getDataForTodayPage();
		if (detectModes.afp_status !== "off" || isAdmin) Promise.all([getAFPWebsiteTokens(), getAFPWebsiteId()]).then(() => {
			bApi.setCurrencySymbol(); getAllAdPlatforms()
			if (detectModes.wtp_status === "off") setReduxComplete(true)
		});
	}, []);



	return React.useMemo(() => {
		if (JSON.parse(status).Status === "Scheduled Maintenance") {
			return (
				<>
					<AppMaintenance status={status.MaintenanceMessage} />
				</>
			)
		};
		if (reduxComplete === false) {
			return (
				<>
					<SelectHead />
					<Loading />
					<LoginFoot />
				</>

			)
		} else {
			return (
				addWebThreatAllow ? <Navigate to="/wtp/today" /> : <Navigate to="/afp/current" />
			);
		};
	}, [reduxComplete]);
};

export default LoadingData;
